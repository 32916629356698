.property-container {
  background-color: #F5F5F6;
}

.property-container .container-section {
  padding: 45px 0;
}

.property-container .container {
  padding: 0 10px;
}

.property-container .content {
  padding: 55px;
  border-radius: 4px;
  background-color: white;
}

.property-container .row {
  padding: 10px 0;
}

.property-container .row.row1 {
  padding-top: 0;
  padding-bottom: 8px;
}

.property-container .back-btn {
  font-size: 36px;
  font-weight: 900;
  color: #191921;
}

.property-container .back-btn .back-icon {
  margin-right: 8px;
  color: var(--blue);
}

.property-container .col-upload {
  padding-bottom: 10px;
  text-align: center;
}

.property-container .col-upload .dotted-wrapper {
  padding: 20px;
  border: 2px dashed lightgrey;
}

.property-container .col-upload span {
  font-size: 12px;
  font-weight: 300;
  color: lightgrey;
}

.property-container .input-wrapper {
  display: flex;
  margin-bottom: 10px;
}

.property-date,
.property-dropdown,
.property-container .input-wrapper .input-icon-wrapper {
  display: flex;
  /*max-width: 190px;*/
  max-width: 80%;
  flex-direction: row;
  align-items: center;
}

.property-container .input-wrapper .input-icon-wrapper img {
  margin-left: 10px;
}

.property-container .input-wrapper .input-icon-wrapper input {
  width: 100%;
}

.input {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
}

.property-container .input-wrapper .input input,
.property-container .input-wrapper input,
.property-container .input-wrapper textarea {
  margin-left: auto;
  padding: 5px 10px;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  background-color: #F5F5F6;
}

.custom {
  margin-left: auto;
}

.property-container .description {
  margin-bottom: 40px;
}

.row-editor,
.property-container .input-wrapper textarea {
  width: 100%;
  margin-left: 45px;
}

.property-container .col-bot {
  text-align: center;
}
.property-container .switch {
  margin-left: auto;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.property-container .switch input {display:none;}

/* The slider */
.property-container .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.property-container .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.property-container input:checked + .slider {
  background-color: var(--blue);
}

.property-container input:focus + .slider {
  box-shadow: 0 0 1px var(--blue);
}

.property-container input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.property-container .slider.round {
  border-radius: 34px;
}

.property-container .slider.round:before {
  border-radius: 50%;
}