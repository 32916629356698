.dash-row .row.translucent h1 {
    margin-left: -15px;
    margin-right: -15px;
}

.row > p {
    padding-left: 10px;
    padding-right: 10px;
}

.row.translucent {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    background-color: rgba(0,0,0,0);
}