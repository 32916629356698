.location-search-input,
.location-search-input:focus,
.location-search-input:active {
    /*box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    border: 10px;*/
    /*display: block;
    width: 100%;*/
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    /*outline: none;*/
}

.autocomplete-dropdown-container {
    border-bottom: 10px;
    border-left: 10px;
    border-right: 10px;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 0 0 2px 2px;
    position: absolute;
    z-index: 1051;
}

.suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
}
.suggestion-item--active {
    padding: 8px;
    text-align: left;
    background-color: #fafafa;
    cursor: pointer;
}