.station-item .buttonstations {
    margin-top: 5px;
    margin-right: 10px;
    padding: 0px 10px;
    display: inline-block;
    outline: none !important;
    border: 2px solid var(--blue);
    background-color: var(--blue);
    border-radius: 4px;
    color: white !important;
    font-size: 14px;
    font-family: 'Lato', sans-serif !important;
    font-weight: 300 !important;
    text-decoration: none !important;
    transition: 0.3s all;
  }
  
  .station-item .buttonstations:hover {
    background-color: #245A8F;
    border-color: #245A8F;
  }
  .stations-x {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .station-item img {
    padding: 20px;
    max-width: 138px;
    min-width: 138px;
  }
  .station-item.station-inactive {
    opacity: 0.5;
    background-color: #CCC;
  }