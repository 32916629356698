.navbar {
    background-color: #222223;
  }
  
  .navbar-brand img {
    width: 160px;
  }
  
  .navbar-toggle {
    border: none;
    outline: none !important;
    color: var(--blue) !important;
  }
  
  
  .nav-link {
    padding-top: 0.1rem !important;
    padding-bottom: 0.2rem !important;
    padding-right: 2.0rem !important;
    color: white !important;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.5px;
    display: inline;
  }
  
  .navbar-toggler .nav-link {
    padding-left: 0px !important;
  }
  
  
  .nav-item {
    padding-top: 0.1rem !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .nav-link:hover {
    color: var(--blue) !important;
  }
  .nav-item.active > .nav-link {
    color: var(--blue) !important;
  }
  
  
  .nav-link.logout  {
    font-weight: 900;
    padding-right: 0px !important;
    letter-spacing: 0.5px;
  }
  