.cover_upload_btn .dx-icon {
    width: 190px;
    height: auto;
}
.dx-button.cover_upload_btn {
    border-style: dashed;
}
.dx-switch,
.dx-switch-wrapper {
    min-width: 50px;
}