@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

:root {
  --blue: #408FDE;
  --blue-dark: #245A8F;
  --green: #47D7AC;
  --green-dark: #1FA57D;
}

html, body {
  font-family: 'Lato';
  background-color: #F5F5F6;
  color: #191921;
}

.button {
  margin-left: 10px;
  margin-right: 10px;
  padding: 0px 10px;
  display: inline-block;
  outline: none !important;
  border: 2px solid var(--blue);
  background-color: var(--blue);
  border-radius: 4px;
  color: white !important;
  font-size: 14px;
  font-weight: 300 !important;
  text-decoration: none !important;
  transition: 0.3s all;
}

.button:hover {
  background-color: var(--blue-dark); /*#245A8F;*/
  border-color: var(--blue-dark); /*#245A8F;*/
}

.buttoncreate {
  padding: 5px 15px;
  outline: none !important;
  border: 2px solid var(--green); /*#47D7AC;*/
  background-color: var(--green); /*#47D7AC;*/
  border-radius: 4px;
  color: white !important;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none !important;
  transition: 0.3s all;
}

.buttoncreate:hover {
  background-color: var(--green-dark); /*#1FA57D;*/
  border-color: var(--green-dark);
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay-fluid {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10; 
  top: 0;
  left: 0;
  background-color: #33333380;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


p {
  font-size: 14px;
  font-weight: 400 !important;
  color: #191921;
  margin-bottom: 0px !important;
}

h1 {
  font-size: 36px;
  font-weight: 900 !important;
  color: #191921;
}

h2 {
  font-size: 16px;
  font-weight: 400 !important;
  color: var(--blue);
  margin-bottom: 0px !important;
}


h5 {
  font-size: 18px;
  font-weight: 500 !important;
}

.dash-row .row {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
}

/*
DX Overrides 
*/
.dx-placeholder {
  color: #6c757d;
}
.dx-validationsummary, .dx-invalid-message {
  color: #dc3545;
}
.dx-textbox.dx-state-focused{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}
@font-face {
  font-family: 'DXIcons';
  src: url(css/icons/dxiconsmaterial.woff2) format('woff2'), url(css/icons/dxiconsmaterial.woff) format('woff'), url(css/icons/dxiconsmaterial.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
.dx-icon {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.dx-icon-add {
  font: 14px/1 DXIcons;
}
.dx-icon-add:before {
  content: '\f00b';
}
.dx-icon-airplane {
  font: 14px/1 DXIcons;
}
.dx-icon-airplane:before {
  content: '\f000';
}
.dx-icon-bookmark {
  font: 14px/1 DXIcons;
}
.dx-icon-bookmark:before {
  content: '\f017';
}
.dx-icon-box {
  font: 14px/1 DXIcons;
}
.dx-icon-box:before {
  content: '\f018';
}
.dx-icon-car {
  font: 14px/1 DXIcons;
}
.dx-icon-car:before {
  content: '\f01b';
}
.dx-icon-card {
  font: 14px/1 DXIcons;
}
.dx-icon-card:before {
  content: '\f019';
}
.dx-icon-cart {
  font: 14px/1 DXIcons;
}
.dx-icon-cart:before {
  content: '\f01a';
}
.dx-icon-chart {
  font: 14px/1 DXIcons;
}
.dx-icon-chart:before {
  content: '\f01c';
}
.dx-icon-check {
  font: 14px/1 DXIcons;
}
.dx-icon-check:before {
  content: '\f005';
}
.dx-icon-clear {
  font: 14px/1 DXIcons;
}
.dx-icon-clear:before {
  content: '\f008';
}
.dx-icon-clock {
  font: 14px/1 DXIcons;
}
.dx-icon-clock:before {
  content: '\f01d';
}
.dx-icon-close {
  font: 14px/1 DXIcons;
}
.dx-icon-close:before {
  content: '\f00a';
}
.dx-icon-coffee {
  font: 14px/1 DXIcons;
}
.dx-icon-coffee:before {
  content: '\f02a';
}
.dx-icon-comment {
  font: 14px/1 DXIcons;
}
.dx-icon-comment:before {
  content: '\f01e';
}
.dx-icon-doc {
  font: 14px/1 DXIcons;
}
.dx-icon-doc:before {
  content: '\f021';
}
.dx-icon-download {
  font: 14px/1 DXIcons;
}
.dx-icon-download:before {
  content: '\f022';
}
.dx-icon-dragvertical {
  font: 14px/1 DXIcons;
}
.dx-icon-dragvertical:before {
  content: '\f038';
}
.dx-icon-edit {
  font: 14px/1 DXIcons;
}
.dx-icon-edit:before {
  content: '\f023';
}
.dx-icon-email {
  font: 14px/1 DXIcons;
}
.dx-icon-email:before {
  content: '\f024';
}
.dx-icon-event {
  font: 14px/1 DXIcons;
}
.dx-icon-event:before {
  content: '\f026';
}
.dx-icon-favorites {
  font: 14px/1 DXIcons;
}
.dx-icon-favorites:before {
  content: '\f025';
}
.dx-icon-find {
  font: 14px/1 DXIcons;
}
.dx-icon-find:before {
  content: '\f027';
}
.dx-icon-filter {
  font: 14px/1 DXIcons;
}
.dx-icon-filter:before {
  content: '\f050';
}
.dx-icon-folder {
  font: 14px/1 DXIcons;
}
.dx-icon-folder:before {
  content: '\f028';
}
.dx-icon-food {
  font: 14px/1 DXIcons;
}
.dx-icon-food:before {
  content: '\f029';
}
.dx-icon-gift {
  font: 14px/1 DXIcons;
}
.dx-icon-gift:before {
  content: '\f02b';
}
.dx-icon-globe {
  font: 14px/1 DXIcons;
}
.dx-icon-globe:before {
  content: '\f02c';
}
.dx-icon-group {
  font: 14px/1 DXIcons;
}
.dx-icon-group:before {
  content: '\f02e';
}
.dx-icon-help {
  font: 14px/1 DXIcons;
}
.dx-icon-help:before {
  content: '\f02f';
}
.dx-icon-home {
  font: 14px/1 DXIcons;
}
.dx-icon-home:before {
  content: '\f030';
}
.dx-icon-image {
  font: 14px/1 DXIcons;
}
.dx-icon-image:before {
  content: '\f031';
}
.dx-icon-info {
  font: 14px/1 DXIcons;
}
.dx-icon-info:before {
  content: '\f032';
}
.dx-icon-key {
  font: 14px/1 DXIcons;
}
.dx-icon-key:before {
  content: '\f033';
}
.dx-icon-like {
  font: 14px/1 DXIcons;
}
.dx-icon-like:before {
  content: '\f034';
}
.dx-icon-map {
  font: 14px/1 DXIcons;
}
.dx-icon-map:before {
  content: '\f035';
}
.dx-icon-menu {
  font: 14px/1 DXIcons;
}
.dx-icon-menu:before {
  content: '\f00c';
}
.dx-icon-message {
  font: 14px/1 DXIcons;
}
.dx-icon-message:before {
  content: '\f024';
}
.dx-icon-money {
  font: 14px/1 DXIcons;
}
.dx-icon-money:before {
  content: '\f036';
}
.dx-icon-music {
  font: 14px/1 DXIcons;
}
.dx-icon-music:before {
  content: '\f037';
}
.dx-icon-overflow {
  font: 14px/1 DXIcons;
}
.dx-icon-overflow:before {
  content: '\f00d';
}
.dx-icon-percent {
  font: 14px/1 DXIcons;
}
.dx-icon-percent:before {
  content: '\f039';
}
.dx-icon-photo {
  font: 14px/1 DXIcons;
}
.dx-icon-photo:before {
  content: '\f03a';
}
.dx-icon-plus {
  font: 14px/1 DXIcons;
}
.dx-icon-plus:before {
  content: '\f00b';
}
.dx-icon-preferences {
  font: 14px/1 DXIcons;
}
.dx-icon-preferences:before {
  content: '\f03b';
}
.dx-icon-product {
  font: 14px/1 DXIcons;
}
.dx-icon-product:before {
  content: '\f03c';
}
.dx-icon-pulldown {
  font: 14px/1 DXIcons;
}
.dx-icon-pulldown:before {
  content: '\f062';
}
.dx-icon-refresh {
  font: 14px/1 DXIcons;
}
.dx-icon-refresh:before {
  content: '\f03d';
}
.dx-icon-remove {
  font: 14px/1 DXIcons;
}
.dx-icon-remove:before {
  content: '\f00a';
}
.dx-icon-revert {
  font: 14px/1 DXIcons;
}
.dx-icon-revert:before {
  content: '\f04c';
}
.dx-icon-runner {
  font: 14px/1 DXIcons;
}
.dx-icon-runner:before {
  content: '\f040';
}
.dx-icon-save {
  font: 14px/1 DXIcons;
}
.dx-icon-save:before {
  content: '\f041';
}
.dx-icon-search {
  font: 14px/1 DXIcons;
}
.dx-icon-search:before {
  content: '\f027';
}
.dx-icon-tags {
  font: 14px/1 DXIcons;
}
.dx-icon-tags:before {
  content: '\f009';
}
.dx-icon-tel {
  font: 14px/1 DXIcons;
}
.dx-icon-tel:before {
  content: '\f003';
}
.dx-icon-tips {
  font: 14px/1 DXIcons;
}
.dx-icon-tips:before {
  content: '\f004';
}
.dx-icon-todo {
  font: 14px/1 DXIcons;
}
.dx-icon-todo:before {
  content: '\f005';
}
.dx-icon-toolbox {
  font: 14px/1 DXIcons;
}
.dx-icon-toolbox:before {
  content: '\f007';
}
.dx-icon-trash {
  font: 14px/1 DXIcons;
}
.dx-icon-trash:before {
  content: '\f03e';
}
.dx-icon-user {
  font: 14px/1 DXIcons;
}
.dx-icon-user:before {
  content: '\f02d';
}
.dx-icon-upload {
  font: 14px/1 DXIcons;
}
.dx-icon-upload:before {
  content: '\f006';
}
.dx-icon-floppy {
  font: 14px/1 DXIcons;
}
.dx-icon-floppy:before {
  content: '\f073';
}
.dx-icon-arrowleft {
  font: 14px/1 DXIcons;
}
.dx-icon-arrowleft:before {
  content: '\f011';
}
.dx-icon-arrowdown {
  font: 14px/1 DXIcons;
}
.dx-icon-arrowdown:before {
  content: '\f015';
}
.dx-icon-arrowright {
  font: 14px/1 DXIcons;
}
.dx-icon-arrowright:before {
  content: '\f00e';
}
.dx-icon-arrowup {
  font: 14px/1 DXIcons;
}
.dx-icon-arrowup:before {
  content: '\f013';
}
.dx-icon-spinleft {
  font: 14px/1 DXIcons;
}
.dx-icon-spinleft:before {
  content: '\f04f';
}
.dx-icon-spinright {
  font: 14px/1 DXIcons;
}
.dx-icon-spinright:before {
  content: '\f04e';
}
.dx-icon-spinnext {
  font: 14px/1 DXIcons;
}
.dx-icon-spinnext:before {
  content: '\f04e';
}
.dx-rtl .dx-icon-spinnext:before {
  content: '\f04f';
}
.dx-icon-spinprev {
  font: 14px/1 DXIcons;
}
.dx-icon-spinprev:before {
  content: '\f04f';
}
.dx-rtl .dx-icon-spinprev:before {
  content: '\f04e';
}
.dx-icon-spindown {
  font: 14px/1 DXIcons;
}
.dx-icon-spindown:before {
  content: '\f001';
}
.dx-icon-spinup {
  font: 14px/1 DXIcons;
}
.dx-icon-spinup:before {
  content: '\f002';
}
.dx-icon-chevronleft {
  font: 14px/1 DXIcons;
}
.dx-icon-chevronleft:before {
  content: '\f012';
}
.dx-icon-chevronright {
  font: 14px/1 DXIcons;
}
.dx-icon-chevronright:before {
  content: '\f010';
}
.dx-icon-chevronnext {
  font: 14px/1 DXIcons;
}
.dx-icon-chevronnext:before {
  content: '\f010';
}
.dx-rtl .dx-icon-chevronnext:before {
  content: '\f012';
}
.dx-icon-chevronprev {
  font: 14px/1 DXIcons;
}
.dx-icon-chevronprev:before {
  content: '\f012';
}
.dx-rtl .dx-icon-chevronprev:before {
  content: '\f010';
}
.dx-icon-chevrondown {
  font: 14px/1 DXIcons;
}
.dx-icon-chevrondown:before {
  content: '\f016';
}
.dx-icon-chevronup {
  font: 14px/1 DXIcons;
}
.dx-icon-chevronup:before {
  content: '\f014';
}
.dx-icon-chevrondoubleleft {
  font: 14px/1 DXIcons;
}
.dx-icon-chevrondoubleleft:before {
  content: '\f042';
}
.dx-icon-chevrondoubleright {
  font: 14px/1 DXIcons;
}
.dx-icon-chevrondoubleright:before {
  content: '\f043';
}
.dx-icon-equal {
  font: 14px/1 DXIcons;
}
.dx-icon-equal:before {
  content: '\f044';
}
.dx-icon-notequal {
  font: 14px/1 DXIcons;
}
.dx-icon-notequal:before {
  content: '\f045';
}
.dx-icon-less {
  font: 14px/1 DXIcons;
}
.dx-icon-less:before {
  content: '\f046';
}
.dx-icon-greater {
  font: 14px/1 DXIcons;
}
.dx-icon-greater:before {
  content: '\f047';
}
.dx-icon-lessorequal {
  font: 14px/1 DXIcons;
}
.dx-icon-lessorequal:before {
  content: '\f048';
}
.dx-icon-greaterorequal {
  font: 14px/1 DXIcons;
}
.dx-icon-greaterorequal:before {
  content: '\f049';
}
.dx-icon-isblank {
  font: 14px/1 DXIcons;
}
.dx-icon-isblank:before {
  content: '\f075';
}
.dx-icon-isnotblank {
  font: 14px/1 DXIcons;
}
.dx-icon-isnotblank:before {
  content: '\f076';
}
.dx-icon-sortup {
  font: 14px/1 DXIcons;
}
.dx-icon-sortup:before {
  content: '\f051';
}
.dx-icon-sortdown {
  font: 14px/1 DXIcons;
}
.dx-icon-sortdown:before {
  content: '\f052';
}
.dx-icon-sortuptext {
  font: 14px/1 DXIcons;
}
.dx-icon-sortuptext:before {
  content: '\f053';
}
.dx-icon-sortdowntext {
  font: 14px/1 DXIcons;
}
.dx-icon-sortdowntext:before {
  content: '\f054';
}
.dx-icon-sorted {
  font: 14px/1 DXIcons;
}
.dx-icon-sorted:before {
  content: '\f055';
}
.dx-icon-expand {
  font: 14px/1 DXIcons;
}
.dx-icon-expand:before {
  content: '\f04a';
}
.dx-icon-collapse {
  font: 14px/1 DXIcons;
}
.dx-icon-collapse:before {
  content: '\f04b';
}
.dx-icon-columnfield {
  font: 14px/1 DXIcons;
}
.dx-icon-columnfield:before {
  content: '\f057';
}
.dx-icon-rowfield {
  font: 14px/1 DXIcons;
}
.dx-icon-rowfield:before {
  content: '\f058';
}
.dx-icon-datafield {
  font: 14px/1 DXIcons;
}
.dx-icon-datafield:before {
  content: '\f056';
}
.dx-icon-fields {
  font: 14px/1 DXIcons;
}
.dx-icon-fields:before {
  content: '\f059';
}
.dx-icon-fieldchooser {
  font: 14px/1 DXIcons;
}
.dx-icon-fieldchooser:before {
  content: '\f05a';
}
.dx-icon-columnchooser {
  font: 14px/1 DXIcons;
}
.dx-icon-columnchooser:before {
  content: '\f04d';
}
.dx-icon-pin {
  font: 14px/1 DXIcons;
}
.dx-icon-pin:before {
  content: '\f05b';
}
.dx-icon-unpin {
  font: 14px/1 DXIcons;
}
.dx-icon-unpin:before {
  content: '\f05c';
}
.dx-icon-pinleft {
  font: 14px/1 DXIcons;
}
.dx-icon-pinleft:before {
  content: '\f05d';
}
.dx-icon-pinright {
  font: 14px/1 DXIcons;
}
.dx-icon-pinright:before {
  content: '\f05e';
}
.dx-icon-contains {
  font: 14px/1 DXIcons;
}
.dx-icon-contains:before {
  content: '\f063';
}
.dx-icon-startswith {
  font: 14px/1 DXIcons;
}
.dx-icon-startswith:before {
  content: '\f064';
}
.dx-icon-endswith {
  font: 14px/1 DXIcons;
}
.dx-icon-endswith:before {
  content: '\f065';
}
.dx-icon-doesnotcontain {
  font: 14px/1 DXIcons;
}
.dx-icon-doesnotcontain:before {
  content: '\f066';
}
.dx-icon-range {
  font: 14px/1 DXIcons;
}
.dx-icon-range:before {
  content: '\f06a';
}
.dx-icon-export {
  font: 14px/1 DXIcons;
}
.dx-icon-export:before {
  content: '\f05f';
}
.dx-icon-exportxlsx {
  font: 14px/1 DXIcons;
}
.dx-icon-exportxlsx:before {
  content: '\f060';
}
.dx-icon-exportpdf {
  font: 14px/1 DXIcons;
}
.dx-icon-exportpdf:before {
  content: '\f061';
}
.dx-icon-exportselected {
  font: 14px/1 DXIcons;
}
.dx-icon-exportselected:before {
  content: '\f06d';
}
.dx-icon-warning {
  font: 14px/1 DXIcons;
}
.dx-icon-warning:before {
  content: '\f06b';
}
.dx-icon-more {
  font: 14px/1 DXIcons;
}
.dx-icon-more:before {
  content: '\f06c';
}
.dx-icon-square {
  font: 14px/1 DXIcons;
}
.dx-icon-square:before {
  content: '\f067';
}
.dx-icon-clearsquare {
  font: 14px/1 DXIcons;
}
.dx-icon-clearsquare:before {
  content: '\f068';
}
.dx-icon-back {
  font: 14px/1 DXIcons;
}
.dx-icon-back:before {
  content: '\f012';
}
.dx-rtl .dx-icon-back:before {
  content: '\f010';
}
.dx-icon-repeat {
  font: 14px/1 DXIcons;
}
.dx-icon-repeat:before {
  content: '\f069';
}
.dx-icon-selectall {
  font: 14px/1 DXIcons;
}
.dx-icon-selectall:before {
  content: '\f070';
}
.dx-icon-unselectall {
  font: 14px/1 DXIcons;
}
.dx-icon-unselectall:before {
  content: '\f071';
}
.dx-icon-print {
  font: 14px/1 DXIcons;
}
.dx-icon-print:before {
  content: '\f072';
}
.dx-icon {
  font: 24px/1'DXIcons';
  line-height: 1;
  white-space: nowrap;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
}