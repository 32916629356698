  .col-upload {
    padding-bottom: 10px;
    text-align: center;
  }
  .col-upload .dotted-wrapper {
    padding: 20px;
    border: 2px dashed lightgrey;
  }
  .col-upload span {
    font-size: 12px;
    font-weight: 300;
    color: lightgrey;
  }
  .image-row.img_tint {
    background-color: var(--blue) !important;
  }
  .col-upload .dotted-wrapper img:not(.uploading_img) {
    height: 120px;
    max-width: unset;
  }
  .col-upload .dotted-wrapper img:not(.large):not(.uploading_img) {
    width: auto;
    max-width: 200px;
  }
  .col-upload img.img_opacity {
    opacity: 0.5;
  }
  .col-upload img.uploading_img {
    position:absolute;
    left: calc(50% - 19px);
    top: calc(50% - 38px);
    z-index: 10;
  }

  .station-detail .btn-save {
    float: right;
    padding: 5px 50px;
    background-color: var(--blue);
    border: 0;
  }

  .station-detail .btn-save:hover {
    background-color: #245A8F;
    border-color: #245A8F;
  }
  .station-detail .back-btn {
    font-size: 36px;
    font-weight: 900;
    color: #191921;
  }
  .station-detail .back-btn:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .station-detail .back-btn svg {
    margin-right: 8px;
    color: var(--blue);
    width: .625em;
  }

  .page-btn {
    font-size: 24px;
    font-weight: 900;
    color: var(--blue);
    padding: 12px;
  }