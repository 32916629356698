  #login {
    background-color: #F5F5F6;
  }

  #login .container {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  
  #login .col-active {
    display: flex;
  }
  
  #login .row.row1 {
    padding-top: 80px;
    background-color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  #login .row.row2 {
    padding-bottom: 80px;
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  #login .space {
    padding: 20px;
  }
  
  #login label {
    color: #A8A8A9;
  }
  
  #login .button-logout {
    margin-top: 5px;
    margin-right: 10px;
    padding: 10px 40px;
    display: inline-block;
    outline: none !important;
    border: 2px solid var(--blue);
    background-color: var(--blue);
    border-radius: 4px;
    color: white !important;
    font-size: 14px;
    letter-spacing: 1px;
    font-family: 'Lato', sans-serif !important;
    font-weight: 900 !important;
    text-decoration: none !important;
    transition: 0.3s all;
  }
  
  #login .button-logout:hover {
    background-color: #245A8F;
    border-color: #245A8F;
  }
  
  @media (max-width: 991.98px) {
    #login img {
      width: 300px;
    }
  }
  
  @media (max-width: 575.98px) {
    #login .container {
      padding-top: 110px;
      padding-bottom: 110px
    }
  }
  
  @media (max-width: 374.98px) {
    #login img {
      width: 200px;
    }
    #login .container {
      padding-top: 50px;
      padding-bottom: 50px
    }
  }
  